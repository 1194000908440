<template>
  <div class="datepicker">
    <div class="toggle-button" :class="{ 'toggle-button-active': value !== null }">
      <unicon name="calender" fill="currentColor" height="14px" width="14px" />
      <span>
        <p>{{ name }}{{ value && ':&nbsp;' }}</p>
        <p v-if="type === 'month'">{{ value ? YMDToMMYY(value) : '' }}</p>
        <p v-else>{{ value ? YMDToStringDMY(value) : '' }}</p>
      </span>
      <unicon name="angle-down" fill="currentColor" height="13px" width="13px" />
    </div>
    <input
      id="date"
      :type="type"
      :value="value"
      :placeholder="placeholder"
      @input="$event.target.value === '' ? $emit('remove') : $emit('change', $event.target.value)"
      class="datepicker-input"
    />
  </div>
</template>

<script>
import { YMDToMMYY, YMDToStringDMY } from '@/dateFormats';

export default {
  name: 'DateFilter',

  data() {
    return {
      YMDToMMYY,
      YMDToStringDMY,
    };
  },

  props: {
    value: {
      type: String,
    },

    name: {
      type: String,
      default: 'Fecha',
    },

    type: {
      type: String,
      default: 'month',
    },

    placeholder: String,
  },

  computed: {},
};
</script>

<style lang="scss" scoped>
.datepicker {
  display: inline-block;
  position: relative;
  width: fit-content;
  height: 35px;
  min-height: 35px;

  &:hover .toggle-button {
    background-color: var(--gray-color-100);
    &-active {
      background-color: var(--main-color-100);
    }
  }
}
.toggle-button {
  width: fit-content;
  height: 100%;
  border-radius: 5px;
  padding: 0 0.575rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: solid 1px var(--gray-color-900);
  color: var(--font-color-300);

  &-active {
    border: solid 1px var(--main-color-300);
    color: var(--main-color-500);
    background-color: var(--main-color-000);

    p:nth-child(1) {
      font-weight: var(--semi-bold);
    }
  }
  span {
    display: flex;
    align-items: center;
  }
  p {
    color: inherit;
    font-weight: var(--regular);
    font-size: 0.825rem;
    &:nth-child(2) {
      white-space: nowrap;
    }
  }
}

.datepicker-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
}
.datepicker-input::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
</style>
